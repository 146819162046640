define("splittypie/components/side-menu-toggle-custom", ["exports", "ember-side-menu/components/side-menu-toggle"], function (_exports, _sideMenuToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sideMenuToggle.default.extend({
    tagName: "button",
    classNames: ["navbar-btn", "btn", "btn-link", "pull-left"]
  });

  _exports.default = _default;
});