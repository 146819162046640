define("splittypie/components/mobile-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "nav",
    classNames: ["navbar", "navbar-default", "navbar-fixed-top", "visible-xs", "toolbar"],
    attributeBindings: ["role"],
    role: "navigation"
  });

  _exports.default = _default;
});