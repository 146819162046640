define("splittypie/components/scroll-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNames: ["scroll-to"],
    href: null,
    duration: 700,
    easing: "swing",
    offset: 200,
    label: null,
    referenceElement: Ember.computed("href", function () {
      var href = Ember.get(this, "href");
      return Ember.$(href);
    }),
    click: function click(event) {
      event.stopPropagation();
      event.preventDefault();
      var referenceElement = Ember.get(this, "referenceElement");
      var offset = Ember.get(this, "offset");
      var duration = Ember.get(this, "duration");
      var easing = Ember.get(this, "easing");
      Ember.$("html, body").animate({
        scrollTop: referenceElement.offset().top - offset
      }, duration, easing);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this._onWindowScroll = this._determineIfActive.bind(this);
      Ember.$(window).on("scroll", this._onWindowScroll);
      Ember.run.schedule("afterRender", function () {
        _this._determineIfActive();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      Ember.$(window).off("scroll", this._onWindowScroll);
    },
    _determineIfActive: function _determineIfActive() {
      var referenceElement = Ember.get(this, "referenceElement");
      var offset = Ember.get(this, "offset");
      var scrollTop = Ember.$(window).scrollTop();
      var elementTop = Math.floor(referenceElement.offset().top);

      if (scrollTop + offset >= elementTop) {
        Ember.$(".scroll-to").removeClass("active");
        this.$().addClass("active");
      }
    }
  });

  _exports.default = _default;
});