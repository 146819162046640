define("splittypie/models/transaction", ["exports", "splittypie/mixins/model-mixin", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _modelMixin, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_modelMixin.default, {
    name: (0, _attr.default)("string"),
    amount: (0, _attr.default)("number"),
    date: (0, _attr.default)("string"),
    event: (0, _relationships.belongsTo)("event", {
      async: false
    }),
    payer: (0, _relationships.belongsTo)("user", {
      async: false
    }),
    participants: (0, _relationships.hasMany)("user", {
      async: false
    }),
    type: (0, _attr.default)("string", {
      defaultValue: "expense"
    }),
    typeOrDefault: Ember.computed("type", {
      // FIXME: I don't like this typeOrDefault
      get: function get() {
        return Ember.get(this, "type") || "expense";
      }
    }),
    month: Ember.computed("date", function () {
      var date = Ember.get(this, "date");

      if (date) {
        return date.substring(0, 7);
      }

      return null;
    }),
    isTransfer: Ember.computed.equal("type", "transfer")
  });

  _exports.default = _default;
});