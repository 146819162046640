define("splittypie/models/user", ["exports", "splittypie/mixins/model-mixin", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _modelMixin, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_modelMixin.default, {
    name: (0, _attr.default)("string"),
    event: (0, _relationships.belongsTo)("event", {
      async: false
    }),
    balance: Ember.computed("event.transactions.[]", function () {
      var _this = this;

      var transactions = Ember.get(this, "event.transactions");
      var paidTransactions = transactions.filterBy("payer", this);
      var owedTransactions = transactions.filter(function (t) {
        return Ember.get(t, "participants").includes(_this);
      });
      var paidMoney = paidTransactions.reduce(function (acc, t) {
        return acc + parseFloat(Ember.get(t, "amount"));
      }, 0);
      var owedMoney = owedTransactions.reduce(function (acc, t) {
        return acc + parseFloat(Ember.get(t, "amount")) / Ember.get(t, "participants").length;
      }, 0);
      return (paidMoney - owedMoney).toFixed(2);
    })
  });

  _exports.default = _default;
});