define("splittypie/services/job-processor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var debug = Ember.Logger.debug;

  var _default = Ember.Service.extend({
    onlineStore: Ember.inject.service(),
    store: Ember.inject.service(),
    process: function process(job) {
      var name = Ember.get(job, "name");
      var payload = JSON.parse(job.get("payload"));

      var _name$split = name.split(/(?=[A-Z])/),
          _name$split2 = _slicedToArray(_name$split, 2),
          jobType = _name$split2[0],
          modelName = _name$split2[1];

      var method = this.commands[jobType];
      debug("Job-processor: Processing job ".concat(name, " with payload"), payload);
      (false && !(method) && Ember.assert("Job ".concat(name, " doesn't exists"), method));
      return method.call(this, modelName, payload).then(function (result) {
        debug("Job-processor: Job ".concat(name, " has successfully completed"));
        return result;
      }).catch(function (error) {
        debug("Job-processor: Job processing error", error);
        throw error;
      });
    },
    commands: {
      // eslint-disable-line
      create: function create(modelName, properties) {
        var onlineStore = Ember.get(this, "onlineStore");
        var offlineStore = Ember.get(this, "store");
        var modelClass = offlineStore.modelFor(modelName);
        var serializer = offlineStore.serializerFor(modelName);
        var normalized = serializer.normalize(modelClass, properties);
        return onlineStore.push(normalized).save();
      },
      update: function update(modelName, properties) {
        var onlineStore = Ember.get(this, "onlineStore");
        var id = properties.id;
        return onlineStore.findRecord(modelName, id).then(function (record) {
          record.updateModel(properties);
          return record.save();
        });
      },
      destroy: function destroy(modelName, properties) {
        var onlineStore = Ember.get(this, "onlineStore");
        var id = properties.id;
        return onlineStore.findRecord(modelName, id).then(function (record) {
          return record.destroyRecord();
        });
      }
    }
  });

  _exports.default = _default;
});