define("splittypie/helpers/transition-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup("router:main");
    }).readOnly(),
    compute: function compute(_ref) {
      var _ref2 = _toArray(_ref),
          routeName = _ref2[0],
          params = _ref2.slice(1);

      var router = Ember.get(this, "router");
      (false && !(router) && Ember.assert("[ember-transition-helper] Unable to lookup router", router));
      return function () {
        for (var _len = arguments.length, invocationArgs = new Array(_len), _key = 0; _key < _len; _key++) {
          invocationArgs[_key] = arguments[_key];
        }

        var args = params.concat(invocationArgs);
        var transitionArgs = params.length ? [routeName].concat(_toConsumableArray(params)) : [routeName];
        router.transitionTo.apply(router, _toConsumableArray(transitionArgs));
        return args;
      };
    }
  });

  _exports.default = _default;
});