define("splittypie/components/base-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    formObject: null,
    modal: Ember.inject.service(),
    saveButtonText: Ember.computed("formObject.{isNew,isSaving}", function () {
      var isNew = Ember.get(this, "formObject.isNew");
      var isSaving = Ember.get(this, "formObject.isSaving");

      if (isSaving) {
        return "Saving...";
      }

      return isNew ? "Create" : "Save";
    }),
    actions: {
      save: function save() {
        var formObject = Ember.get(this, "formObject");

        if (formObject.updateModel()) {
          this.onModelUpdated(Ember.get(formObject, "model"));
        }
      },
      delete: function _delete() {
        var _this = this;

        var model = Ember.get(this, "formObject.model");
        Ember.get(this, "modal").onConfirm(function () {
          return _this.onDelete(model);
        });
      }
    }
  });

  _exports.default = _default;
});