define("splittypie/templates/components/transaction-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zurYIYp8",
    "block": "{\"symbols\":[\"transactionGroup\",\"transaction\"],\"statements\":[[4,\"if\",[[20,[\"anyTransactions\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"transaction-list-header\",null,[[\"transactions\"],[[20,[\"expenses\"]]]]],false],[0,\"\\n\"],[4,\"each\",[[20,[\"transactionsByMonth\"]]],null,{\"statements\":[[4,\"if\",[[20,[\"anyTransactionWithDate\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"transaction-list-date\"],[7],[0,\"\\n\"],[4,\"if\",[[19,1,[\"month\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"inline-svg\",[\"calendar\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n          \"],[6,\"strong\"],[7],[1,[25,\"print-month-year\",[[19,1,[\"month\"]]],null],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[6,\"strong\"],[7],[0,\"The other day\"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"each\",[[19,1,[\"transactions\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"transaction-list-item\",null,[[\"transaction\",\"onClick\"],[[19,2,[]],[25,\"action\",[[19,0,[]],\"edit\",[19,2,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"alert alert-info\"],[9,\"role\",\"alert\"],[7],[0,\"\\n    There are no transactions yet.\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/transaction-list.hbs"
    }
  });

  _exports.default = _default;
});