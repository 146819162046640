define("splittypie/templates/components/nav-link-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fGENBKUo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"a\"],[9,\"href\",\"\"],[10,\"title\",[18,\"title\"],null],[7],[11,1],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/nav-link-to.hbs"
    }
  });

  _exports.default = _default;
});