define("splittypie/components/transaction-form", ["exports", "splittypie/components/base-form"], function (_exports, _baseForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseForm.default.extend({
    formObject: Ember.computed.alias("transaction"),
    didInsertElement: function didInsertElement() {
      if (Ember.get(this, "formObject.isNew")) {
        this.$(".transaction-name").focus();
      }
    },
    maxDate: Ember.computed(function () {
      return "".concat(new Date().getFullYear(), "-12-31");
    })
  });

  _exports.default = _default;
});