define("splittypie/components/event-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    readonly: true,
    classNames: ["event-url"],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // workaround select after animations


      Ember.run.later(function () {
        _this.$().focus().select();
      }, 500);
    }
  });

  _exports.default = _default;
});