define("splittypie/templates/shared/errors/unknown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gF6RoJ2m",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"text-center\"],[7],[0,\"\\n  \"],[6,\"h1\"],[7],[0,\"Something went wrong\"],[8],[0,\"\\n  \"],[6,\"p\"],[9,\"class\",\"lead\"],[7],[0,\"\\n    Please try refresh page. If it still doesn't work you may want return to the \"],[4,\"link-to\",[\"index\"],null,{\"statements\":[[0,\"Home page\"]],\"parameters\":[]},null],[0,\".\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/shared/errors/unknown.hbs"
    }
  });

  _exports.default = _default;
});