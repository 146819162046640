define("splittypie/services/local-storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isLocalStorageSupported: Ember.computed(function () {
      if (localStorage) {
        try {
          localStorage.setItem("localStorageTest", 1);
          localStorage.removeItem("localStorageTest");
          return true;
        } catch (e) {
          (false && Ember.warn("Browser does not support localstorage"));
        }
      }

      return false;
    }),
    setItem: function setItem(key, value) {
      if (Ember.get(this, "isLocalStorageSupported")) {
        localStorage.setItem(key, value);
      }
    },
    getItem: function getItem(key) {
      if (Ember.get(this, "isLocalStorageSupported")) {
        return localStorage.getItem(key);
      }

      return null;
    },
    removeItem: function removeItem(key) {
      if (Ember.get(this, "isLocalStorageSupported")) {
        localStorage.removeItem(key);
      }
    },
    remove: function remove(entryName, id) {
      (false && !(entryName) && Ember.assert("First argument entryName must be present", entryName));

      if (Ember.get(this, "isLocalStorageSupported")) {
        var items = this.findAll(entryName);
        items.removeObject(items.findBy("id", id));
        localStorage.setItem(entryName, JSON.stringify(items));
      }
    }
  });

  _exports.default = _default;
});