define("splittypie/templates/components/quick-add-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YHTHhZz3",
    "block": "{\"symbols\":[],\"statements\":[[6,\"form\"],[7],[0,\"\\n\"],[4,\"modal-dialog\",null,null,{\"statements\":[[0,\"    \"],[6,\"div\"],[9,\"class\",\"modal-header\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"close\"],[9,\"data-dismiss\",\"modal\"],[9,\"aria-hidden\",\"true\"],[9,\"aria-label\",\"Dismiss\"],[7],[0,\"×\"],[8],[0,\"\\n      \"],[6,\"h4\"],[9,\"class\",\"modal-title\"],[7],[0,\"Quick Add!\"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"modal-body pull-up-30\"],[7],[0,\"\\n      \"],[6,\"div\"],[10,\"class\",[26,[\"form-group \",[25,\"if\",[[20,[\"isFormInvalid\"]],\"has-error\"],null]]]],[7],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"value\",\"class\",\"placeholder\"],[[20,[\"transactionToParse\"]],\"form-control transaction-parse\",\"Example: 10 tickets\"]]],false],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"help-block\"],[7],[0,\"\\n          More examples: 03/24 40 Museum Tickets | 12 20 shopping\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"transaction\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"transaction-list-item\",null,[[\"transaction\"],[[20,[\"transaction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"modal-footer\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"btn btn-default pull-left btn-add-with-details\"],[9,\"data-dismiss\",\"modal\"],[3,\"action\",[[19,0,[]],\"addWithDetails\"]],[7],[0,\"Add With Details\"],[8],[0,\"\\n      \"],[6,\"button\"],[9,\"type\",\"submit\"],[9,\"class\",\"btn btn-primary btn-add\"],[10,\"disabled\",[18,\"isFormInvalid\"],null],[9,\"data-dismiss\",\"modal\"],[3,\"action\",[[19,0,[]],\"add\"]],[7],[0,\"Add\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/quick-add-form.hbs"
    }
  });

  _exports.default = _default;
});