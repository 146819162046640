define("splittypie/repositories/transaction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    syncQueue: Ember.inject.service(),
    save: function save(event, transaction) {
      var _this = this;

      var operation = "updateTransaction";

      if (Ember.get(transaction, "isNew")) {
        operation = "createTransaction";
        Ember.get(event, "transactions").addObject(transaction);
      }

      return event.save().then(function () {
        var payload = transaction.serialize({
          includeId: true
        }); // workaround, if I don't save here model will remain in isNew or dirty state
        // offline adapter for transaction is overridden to prevent from
        // saving second time on "transactions" node to indexedDB
        // localforage adapter should deal with it but it doesn't

        return Ember.get(_this, "syncQueue").enqueue(operation, payload).then(function () {
          return transaction.save();
        });
      });
    },
    remove: function remove(transaction) {
      var _this2 = this;

      var event = Ember.get(transaction, "event");
      var eventId = Ember.get(event, "id");
      var id = Ember.get(transaction, "id");
      Ember.get(event, "transactions").removeObject(transaction);
      return event.save().then(function () {
        // workaround, localforage adapter should deal with it
        // but it doesn't unload record from store
        return Ember.get(_this2, "syncQueue").enqueue("destroyTransaction", {
          eventId: eventId,
          id: id
        }).then(function () {
          return transaction.destroyRecord();
        });
      });
    }
  });

  _exports.default = _default;
});