define("splittypie/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modal: Ember.inject.service(),
    syncer: Ember.inject.service(),
    notify: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.get(this, "modal").on("show", this, "showModal");
      Ember.get(this, "modal").on("remove", this, "removeModal");
      Ember.get(this, "syncer").on("conflict", this, "synchronizationConflict");
    },
    model: function model() {
      return Ember.RSVP.hash({
        currencies: this.store.findAll("currency"),
        previousEvents: this.store.findAll("event")
      });
    },
    setupController: function setupController() {
      this._super.apply(this, arguments);

      this.removePreloader();
    },
    showModal: function showModal(options) {
      var model = this.modelFor("application");
      Ember.setProperties(model, options);
      this.render("modals/".concat(options.name), {
        into: "application",
        outlet: "modal",
        model: model
      });
    },
    removeModal: function removeModal() {
      // Not sure why tests are failing removing outlet, below guards prevents tests from failing
      // TODO: FIXME: try to figure out this one day, it's related to adding quick add feature

      /* if (!this.connections) return; */

      /* if (!this.router.currentHandlerInfos) return; */
      this.disconnectOutlet({
        outlet: "modal",
        parentView: "application"
      });
    },
    synchronizationConflict: function synchronizationConflict(conflict) {
      if (conflict.type === "not-found-online" && conflict.modelName === "event") {
        var message = "\nLooks like event ".concat(conflict.model.name, " was removed from the online storage.\nWe are marking it as \"Offline\", you could synchronize it back to online store\nin event's details view.\n");
        Ember.get(this, "notify").error(message, {
          closeAfter: null
        });
      }
    },
    removePreloader: function removePreloader() {
      Ember.run.schedule("afterRender", this, function () {
        Ember.$("#preloader").remove();
      });
    },
    actions: {
      invokeAction: function invokeAction(action) {
        action();
      },
      removeModal: function removeModal() {
        this.removeModal();
      }
    }
  });

  _exports.default = _default;
});