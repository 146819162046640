define("splittypie/components/transaction-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "div",
    classNames: ["list-group"],
    expenses: Ember.computed.filterBy("transactions", "typeOrDefault", "expense"),
    anyTransactions: Ember.computed.notEmpty("transactions"),
    transactionsByMonth: Ember.computed("transactions.[]", function () {
      var result = [];
      var transactions = Ember.get(this, "transactions").sortBy("date").reverse();
      transactions.forEach(function (transaction) {
        var month = Ember.get(transaction, "month");
        var group = result.findBy("month", month);

        if (!group) {
          result.pushObject(Ember.Object.create({
            month: month,
            transactions: [transaction]
          }));
        } else {
          Ember.get(group, "transactions").pushObject(transaction);
        }
      });
      return result;
    }),
    anyTransactionWithDate: Ember.computed("transactions.[]", function () {
      var transactions = Ember.get(this, "transactions");
      return transactions.any(function (transaction) {
        return !!Ember.get(transaction, "date");
      });
    }),
    actions: {
      edit: function edit(transaction) {
        this.onEdit(transaction);
      }
    }
  });

  _exports.default = _default;
});