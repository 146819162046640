define("splittypie/components/transaction-list-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["transaction-list-header"],
    currency: Ember.computed.alias("transactions.firstObject.event.currency.code"),
    count: Ember.computed.alias("transactions.length"),
    total: Ember.computed("transactions.[]", function () {
      var transactions = Ember.get(this, "transactions");
      return transactions.reduce(function (prev, curr) {
        return prev + parseFloat(Ember.get(curr, "amount"));
      }, 0);
    })
  });

  _exports.default = _default;
});