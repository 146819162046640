define("splittypie/components/transaction-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["list-group-item", "btn", "btn-default", "transaction-list-item"],
    participants: Ember.computed("transaction.participants", function () {
      return Ember.get(this, "transaction.participants").getEach("name").join(", ");
    }),
    click: function click() {
      var onClick = Ember.get(this, "onClick");

      if (typeof onClick === "function") {
        onClick();
      }
    }
  });

  _exports.default = _default;
});