define("splittypie/services/sync-queue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var debug = Ember.Logger.debug;

  var _default = Ember.Service.extend(Ember.Evented, {
    store: Ember.inject.service(),
    jobProcessor: Ember.inject.service(),
    connection: Ember.inject.service(),
    pendingJobs: null,
    isProcessing: false,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, "pendingJobs", []);
    },
    enqueue: function enqueue(name, payload) {
      var _this = this;

      debug("Sync-queue: Creating offline job for ".concat(name, ": "), payload);
      return this._createAndSaveJob(name, payload).then(function (job) {
        if (Ember.get(_this, "connection.isOnline")) {
          debug("Sync-queue: Adding job ".concat(name, " to pendingJobs array"));
          Ember.get(_this, "pendingJobs").addObject(job);
        }
      });
    },
    flush: function flush() {
      var _this2 = this;

      debug("Sync-queue: Flushing offline jobs");
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.get(_this2, "store").findAll("sync-job").then(function (jobs) {
          var arrayJobs = jobs.sortBy("createdAt").toArray();

          if (arrayJobs.length === 0) {
            debug("Sync-queue: No jobs to flush");
            resolve();
          } else {
            _this2.one("flushed", resolve);

            Ember.get(_this2, "pendingJobs").pushObjects(arrayJobs);
          }
        });
      });
    },
    pendingJobsDidChange: Ember.observer("pendingJobs.[]", function () {
      var isProcessing = Ember.get(this, "isProcessing");

      if (!isProcessing) {
        this._processNext();
      }
    }),
    _processNext: function _processNext() {
      var _this3 = this;

      var jobProcessor = Ember.get(this, "jobProcessor");
      var pendingJobs = Ember.get(this, "pendingJobs");
      var job = pendingJobs.objectAt(0);

      if (!job) {
        return;
      }

      this.set("isProcessing", true);
      jobProcessor.process(job).catch(function (error) {
        _this3.trigger("error", error);
      }).finally(function () {
        Ember.get(_this3, "pendingJobs").removeAt(0);
        var moreJobsToProcess = Ember.get(_this3, "pendingJobs.length") > 0;
        job.destroyRecord().then(function () {
          if (moreJobsToProcess) {
            _this3._processNext();
          } else {
            Ember.set(_this3, "isProcessing", false);
            debug("Sync-queue: Sync queue is flushed");

            _this3.trigger("flushed");
          }
        });
      });
    },
    _createAndSaveJob: function _createAndSaveJob(name, payload) {
      var job = Ember.get(this, "store").createRecord("sync-job", {
        name: name,
        payload: JSON.stringify(payload)
      });
      return job.save();
    }
  });

  _exports.default = _default;
});