define("splittypie/forms/user", ["exports", "ember-cp-validations", "splittypie/forms/form-object"], function (_exports, _emberCpValidations, _formObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
        max: 50
      })]
    }
  });

  var _default = _formObject.default.extend(Validations, {
    modelName: "user",
    init: function init() {
      this._super.apply(this, arguments);

      var model = Ember.get(this, "model");
      Ember.set(this, "name", Ember.get(model, "name"));
    },
    updateModelAttributes: function updateModelAttributes() {
      var model = Ember.get(this, "model");
      Ember.setProperties(model, Ember.getProperties(this, "name"));
    }
  });

  _exports.default = _default;
});