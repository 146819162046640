define("splittypie/controllers/event/transactions/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["type", "amount", "name", "date", "from", "to"]
  });

  _exports.default = _default;
});