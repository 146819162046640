define("splittypie/components/switch-user-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "div",
    classNames: ["dropdown", "user-dropdown"],
    otherUsers: Ember.computed("selected", "users", function () {
      var users = Ember.get(this, "users");
      var currentUser = Ember.get(this, "selected");
      return users.rejectBy("id", Ember.get(currentUser, "id"));
    })
  });

  _exports.default = _default;
});