define("splittypie/components/previous-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localStorage: Ember.inject.service(),
    modal: Ember.inject.service(),
    classNames: ["previous-events-container"],
    attributeBindings: ["id"],
    tagName: "section",
    id: "events",
    anyEvents: Ember.computed.notEmpty("events"),
    actions: {
      remove: function remove(event) {
        var _this = this;

        var storage = Ember.get(this, "localStorage");
        var showModal = storage.getItem("remove-prev-events-got-it") !== "true";

        if (showModal) {
          var yes = function yes() {
            _this._removeEventFromOfflineStore(event);

            Ember.get(_this, "modal").trigger("hide");
          };

          Ember.get(this, "modal").trigger("show", {
            name: "remove-previous-event",
            actions: {
              yes: yes,
              yes_remember: function yes_remember() {
                storage.setItem("remove-prev-events-got-it", "true");
                yes();
              }
            },
            event: event
          });
        } else {
          this._removeEventFromOfflineStore(event);
        }
      }
    },
    _removeEventFromOfflineStore: function _removeEventFromOfflineStore(event) {
      var storage = Ember.get(this, "localStorage");
      var lastEventId = storage.getItem("lastEventId"); // remove only from offline store

      event.destroyRecord();

      if (lastEventId === event.id) {
        storage.removeItem("lastEventId");
      }
    }
  });

  _exports.default = _default;
});