define("splittypie/components/settlement-transfer-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "ul",
    classNames: ["list-unstyled"],
    anyTransfers: Ember.computed.notEmpty("transfers"),
    transfers: Ember.computed("users.@each.balance", function () {
      var users = Ember.get(this, "users");
      var currency = Ember.get(this, "users.firstObject.event.currency");
      var owed = users.filter(function (u) {
        return Ember.get(u, "balance") < 0;
      }).map(convertToUserAmount);
      var paid = users.filter(function (u) {
        return Ember.get(u, "balance") > 0;
      }).map(convertToUserAmount);
      var transfers = [];

      function convertToUserAmount(user) {
        return Ember.Object.create({
          user: user,
          amount: Math.abs(Ember.get(user, "balance"))
        });
      }

      while (owed.length > 0 && paid.length > 0) {
        var sender = owed.objectAt(0);
        var recipient = paid.objectAt(0);
        var canGive = sender.get("amount");
        var demand = recipient.get("amount");
        var possibleTransfer = Math.min(canGive, demand);
        sender.set("amount", canGive - possibleTransfer);

        if (Ember.get(sender, "amount") === 0) {
          owed.removeObject(sender);
        }

        recipient.set("amount", demand - possibleTransfer);

        if (Ember.get(recipient, "amount") === 0) {
          paid.removeObject(recipient);
        }

        transfers.pushObject(Ember.Object.create({
          sender: Ember.get(sender, "user"),
          recipient: Ember.get(recipient, "user"),
          amount: possibleTransfer.toFixed(2),
          currency: currency
        }));
      }

      return transfers;
    })
  });

  _exports.default = _default;
});