define("splittypie/templates/components/user-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+geWOmh9",
    "block": "{\"symbols\":[\"user\",\"index\"],\"statements\":[[4,\"each\",[[20,[\"users\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"user-form\",null,[[\"user\",\"index\",\"isSubmitted\",\"isRemoveDisabled\",\"onDelete\"],[[19,1,[]],[19,2,[]],[20,[\"isSubmitted\"]],[20,[\"isRemoveDisabled\"]],[25,\"action\",[[19,0,[]],\"deleteUser\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/user-list.hbs"
    }
  });

  _exports.default = _default;
});