define("splittypie/templates/components/calendar-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DnkUuo5S",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"month\"],[7],[1,[18,\"month\"],false],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"day\"],[7],[1,[18,\"day\"],false],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/calendar-page.hbs"
    }
  });

  _exports.default = _default;
});