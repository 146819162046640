define("splittypie/components/user-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "ul",
    classNames: ["list-unstyled"],
    isRemoveDisabled: Ember.computed.lte("users.length", 2),
    // validate() {
    //     const validateUsers = get(this, "users").getEach("form").invoke("validate");
    //     const validateThis = this._super.apply(this, arguments);
    //     return Promise.all([validateThis].concat(validateUsers));
    // },
    actions: {
      deleteUser: function deleteUser(user) {
        Ember.get(this, "users").removeObject(user);
      }
    }
  });

  _exports.default = _default;
});