define("splittypie/services/user-context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    localStorage: Ember.inject.service(),
    currentUser: null,
    save: function save(eventId, userId) {
      Ember.get(this, "localStorage").setItem("event-".concat(eventId, "-current-user"), userId);
    },
    load: function load(event) {
      var userId = Ember.get(this, "localStorage").getItem("event-".concat(Ember.get(event, "id"), "-current-user"));
      var user = Ember.get(event, "users").findBy("id", userId);

      if (user) {
        Ember.set(this, "currentUser", user);
      }

      return user;
    },
    change: function change(event, user) {
      this.save(Ember.get(event, "id"), Ember.get(user, "id"));
      Ember.set(this, "currentUser", user);
    }
  });

  _exports.default = _default;
});