define("splittypie/routes/new", ["exports", "splittypie/utils/country-to-currency-code"], function (_exports, _countryToCurrencyCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userCountryCode: Ember.inject.service(),
    userContext: Ember.inject.service(),
    eventRepository: Ember.inject.service(),
    model: function model() {
      // FIXME: Don't like this model building
      return Ember.RSVP.hash({
        defaultCurrency: this._getDefaultCurrency(),
        event: {
          users: [{}, {}]
        },
        currencies: this.store.findAll("currency")
      });
    },
    _getDefaultCurrency: function _getDefaultCurrency() {
      var _this = this;

      return Ember.get(this, "userCountryCode").getCountryCode().then(function (countryCode) {
        var currencyCode = (0, _countryToCurrencyCode.default)(countryCode) || "USD";
        return _this.store.findRecord("currency", currencyCode);
      });
    },
    setupController: function setupController(controller, models) {
      this._super(controller, models);

      Ember.set(models.event, "currency", models.defaultCurrency);
      var eventForm = Ember.get(this, "formFactory").createForm("event", models.event);
      Ember.setProperties(controller, {
        event: eventForm,
        currencies: models.currencies
      });
    },
    actions: {
      modelUpdated: function modelUpdated(event) {
        var _this2 = this;

        Ember.get(this, "eventRepository").save(event).then(function () {
          Ember.get(_this2, "userContext").save(Ember.get(event, "id"), Ember.get(event, "users.firstObject.id"));

          _this2.transitionTo("event.index", event);
        });
      }
    }
  });

  _exports.default = _default;
});