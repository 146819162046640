define("splittypie/services/store", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Store = _emberData.default.Store;

  var _default = Store.extend({
    adapter: "offline/application",
    serializerFor: function serializerFor(modelName) {
      return this._super("offline/".concat(modelName));
    },
    adapterFor: function adapterFor(modelName) {
      return this._super("offline/".concat(modelName));
    }
  });

  _exports.default = _default;
});