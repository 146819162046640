define("splittypie/components/user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    classNames: ["user-form"],
    placeholder: Ember.computed("index", function () {
      var index = Ember.get(this, "index");
      return index === 0 ? "Your name" : "Your friend's name";
    }),
    actions: {
      delete: function _delete(user) {
        this.onDelete(user);
      }
    }
  });

  _exports.default = _default;
});