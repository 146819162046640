define("splittypie/routes/event/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modal: Ember.inject.service(),
    notify: Ember.inject.service(),
    transactionRepository: Ember.inject.service(),
    actions: {
      settleUp: function settleUp(transfer) {
        var _this = this;

        Ember.get(this, "modal").trigger("show", {
          name: "settle-up",
          actions: {
            yes: function yes() {
              var event = _this.modelFor("event");

              var transaction = _this.store.createRecord("transaction", {
                payer: Ember.get(transfer, "sender"),
                participants: [Ember.get(transfer, "recipient")],
                amount: Ember.get(transfer, "amount"),
                type: "transfer",
                date: new Date().toISOString().substring(0, 10)
              });

              _this.get("transactionRepository").save(event, transaction).then(function () {
                Ember.get(_this, "modal").trigger("hide");
                Ember.get(_this, "notify").success("Everything settled");
              }).catch(function () {
                Ember.get(_this, "modal").trigger("hide");
                Ember.get(_this, "notify").error("An error occured");
              });
            }
          },
          transfer: transfer
        });
      }
    }
  });

  _exports.default = _default;
});