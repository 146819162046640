define("splittypie/templates/modals/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1RzLqL81",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"modal-header\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"close\"],[9,\"data-dismiss\",\"modal\"],[9,\"aria-hidden\",\"true\"],[9,\"aria-label\",\"Dismiss\"],[7],[0,\"×\"],[8],[0,\"\\n    \"],[6,\"h4\"],[9,\"class\",\"modal-title\"],[7],[0,\"Confirmation\"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal-body\"],[7],[0,\"\\n    Are you sure?\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal-footer\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"btn btn-primary\"],[3,\"action\",[[19,0,[]],\"invokeAction\",[20,[\"model\",\"actions\",\"ok\"]]]],[7],[0,\"Yes\"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"btn btn-default\"],[9,\"data-dismiss\",\"modal\"],[7],[0,\"No\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/modals/confirm.hbs"
    }
  });

  _exports.default = _default;
});