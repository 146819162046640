define("splittypie/templates/components/date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GUbxE870",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"isMobile\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"input\",null,[[\"type\",\"value\",\"class\",\"min\",\"max\"],[\"date\",[20,[\"value\"]],[20,[\"dateInputClasses\"]],[20,[\"min\"]],[20,[\"max\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"pikaday-input\",null,[[\"class\",\"value\",\"minDate\",\"maxDate\",\"format\",\"useUTC\",\"onSelection\"],[[20,[\"dateInputClasses\"]],[20,[\"pikadayValue\"]],[20,[\"pikadayMin\"]],[20,[\"pikadayMax\"]],\"YYYY-MM-DD\",true,[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"pikadayValue\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/date-picker.hbs"
    }
  });

  _exports.default = _default;
});