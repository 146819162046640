define("splittypie/forms/form-object", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    store: Ember.inject.service(),
    formFactory: Ember.inject.service(),
    parent: null,
    model: null,
    isSubmitted: false,
    isModelEmberDataModel: Ember.computed("model", function () {
      return _emberData.default.Model.detectInstance(this.get("model"));
    }),
    isSaving: Ember.computed.oneWay("model.isSaving"),
    isNew: Ember.computed("isModelEmberDataModel", "model.isNew", function () {
      return Ember.get(this, "isModelEmberDataModel") ? Ember.get(this, "model.isNew") : true;
    }),
    parentSubmittedChanged: Ember.on("init", Ember.observer("parent.isSubmitted", function () {
      Ember.set(this, "isSubmitted", Ember.get(this, "parent.isSubmitted"));
    })),
    formErrors: Ember.computed("isSubmitted", function () {
      return Ember.get(this, "isSubmitted") ? Ember.get(this, "validations.attrs") : {};
    }),
    createInnerForm: function createInnerForm(name, model) {
      return Ember.get(this, "formFactory").createForm(name, model, {
        parent: this
      });
    },
    updateModel: function updateModel() {
      Ember.set(this, "isSubmitted", true);

      if (Ember.get(this, "validations.isValid")) {
        this.applyChangesToModel();
        return true;
      }

      return false;
    },
    applyChangesToModel: function applyChangesToModel() {
      this.createModelIfNotEmberDataModel();
      this.updateInnerForms();
      this.updateModelAttributes();
    },
    createModelIfNotEmberDataModel: function createModelIfNotEmberDataModel() {
      if (!Ember.get(this, "isModelEmberDataModel")) {
        Ember.set(this, "model", Ember.get(this, "store").createRecord(Ember.get(this, "modelName")));
      }
    },
    updateInnerForms: function updateInnerForms() {
      this._getInnerForms().invoke("applyChangesToModel");
    },
    updateModelAttributes: function updateModelAttributes() {},
    _getInnerForms: function _getInnerForms() {
      var _this = this;

      var innerForms = Ember.getWithDefault(this, "innerForms", []);
      return innerForms.map(function (attribute) {
        return Ember.get(_this, attribute);
      }).flatten();
    }
  });

  _exports.default = _default;
});