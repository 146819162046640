define("splittypie/components/date-picker", ["exports", "splittypie/utils/is-mobile"], function (_exports, _isMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: null,
    min: null,
    max: null,
    isMobile: _isMobile.default.property(),
    pikadayMin: Ember.computed("min", function () {
      return new Date(Ember.get(this, "min"));
    }),
    pikadayMax: Ember.computed("max", function () {
      return new Date(Ember.get(this, "max"));
    }),
    pikadayValue: Ember.computed("value", function () {
      var value = Ember.get(this, "value");
      return new Date(value);
    }),
    pikadayValueDidChange: Ember.observer("pikadayValue", function () {
      var pikadayValue = Ember.get(this, "pikadayValue");

      if (pikadayValue) {
        Ember.set(this, "value", pikadayValue.toISOString().substring(0, 10));
      } else {
        Ember.set(this, "value", null);
      }
    }),
    // eslint-disable-next-line
    dateInputClasses: Ember.computed("className", function () {
      var className = Ember.get(this, "className");
      return "".concat(className, " form-control");
    })
  });

  _exports.default = _default;
});