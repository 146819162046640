define("splittypie/templates/modals/quick-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vGhg18mE",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"quick-add-form\",null,[[\"onAdd\",\"onAddWithDetails\"],[[25,\"route-action\",[\"quickAdd\"],null],[25,\"route-action\",[\"quickAddWithDetails\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/modals/quick-add.hbs"
    }
  });

  _exports.default = _default;
});