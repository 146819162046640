define("splittypie/initializers/connection", ["exports", "splittypie/services/connection"], function (_exports, _connection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // FIXME: REMOVE THIS LATER
  function initialize(application) {
    application.register("service:connection", _connection.default); // inject user-context to controllers

    application.inject("controller", "connection", "service:connection");
  }

  var _default = {
    name: "connection",
    initialize: initialize
  };
  _exports.default = _default;
});