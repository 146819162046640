define("splittypie/initializers/remember-scroll", ["exports", "splittypie/mixins/remember-scroll"], function (_exports, _rememberScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.Route.reopen(_rememberScroll.default);
  }

  var _default = {
    name: "remember-scroll",
    initialize: initialize
  };
  _exports.default = _default;
});