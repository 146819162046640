define("splittypie/instance-initializers/syncer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(applicationInstance) {
    Ember.debug("initialize syncer");
    applicationInstance.lookup("service:syncer");
  }

  var _default = {
    name: "syncer",
    initialize: initialize
  };
  _exports.default = _default;
});