define("splittypie/templates/components/previous-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PHGQULsX",
    "block": "{\"symbols\":[\"event\"],\"statements\":[[4,\"if\",[[20,[\"anyEvents\"]]],null,{\"statements\":[[0,\"  \"],[6,\"h3\"],[9,\"class\",\"text-center\"],[7],[0,\"You have already created the following events\"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"previous-events\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"events\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"btn-group\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"event.index\",[19,1,[\"id\"]]],[[\"class\"],[\"btn btn-default\"]],{\"statements\":[[0,\"          \"],[6,\"strong\"],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[6,\"button\"],[9,\"class\",\"btn btn-default\"],[9,\"title\",\"Remove reference from this computer. The event will still be reachable by url.\"],[9,\"aria-label\",\"Remove event reference locally\"],[3,\"action\",[[19,0,[]],\"remove\",[19,1,[]]]],[7],[0,\"\\n          \"],[1,[25,\"inline-svg\",[\"close\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/previous-events.hbs"
    }
  });

  _exports.default = _default;
});