define("splittypie/routes/event/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    localStorage: Ember.inject.service(),
    notify: Ember.inject.service(),
    eventRepository: Ember.inject.service(),
    syncer: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        event: this.modelFor("event"),
        currencies: this.store.findAll("currency")
      });
    },
    setupController: function setupController(controller, models) {
      this._super(controller, models);

      var eventForm = Ember.get(this, "formFactory").createForm("event", models.event);
      Ember.setProperties(controller, {
        event: eventForm,
        currencies: models.currencies
      });
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: "application"
      });
    },
    actions: {
      delete: function _delete(event) {
        var _this = this;

        Ember.get(this, "eventRepository").remove(event).then(function () {
          var storage = Ember.get(_this, "localStorage");
          storage.removeItem("lastEventId");

          _this.transitionTo("index");

          Ember.get(_this, "notify").success("Event has been deleted.");
        });
      },
      modelUpdated: function modelUpdated(event) {
        var _this2 = this;

        Ember.get(this, "eventRepository").save(event).then(function () {
          _this2.transitionTo("event");

          Ember.get(_this2, "notify").success("Event has been changed");
        });
      },
      syncOnline: function syncOnline(event) {
        var _this3 = this;

        Ember.get(this, "syncer").pushEventOnline(event).then(function () {
          Ember.get(_this3, "notify").success("Event was successfully synced");
        });
      }
    }
  });

  _exports.default = _default;
});