define("splittypie/templates/components/user-balance-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hul4yD/m",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[4,\"if\",[[20,[\"anyUsers\"]]],null,{\"statements\":[[0,\"  \"],[6,\"h4\"],[7],[0,\"Individual balance\"],[8],[0,\"\\n\\n  \"],[6,\"div\"],[9,\"class\",\"panel panel-default\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"panel-body\"],[7],[0,\"\\n      \"],[6,\"table\"],[9,\"class\",\"table table-hover no-border user-balance-list\"],[9,\"role\",\"presentation\"],[7],[0,\"\\n        \"],[6,\"tbody\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"sortedUsers\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"user-balance-list-item\",null,[[\"user\"],[[19,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/user-balance-list.hbs"
    }
  });

  _exports.default = _default;
});