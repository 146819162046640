define("splittypie/services/connection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var debug = Ember.Logger.debug;

  var _default = Ember.Service.extend({
    state: "offline",
    isOnline: Ember.computed.equal("state", "online"),
    isOffline: Ember.computed.equal("state", "offline"),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.set(this, "state", window.navigator.onLine ? "online" : "offline");

      this._onOfflineHandler = function () {
        debug("Connection State: offline");
        Ember.set(_this, "state", "offline");
      };

      this._onOnlineHandler = function () {
        debug("Connection State: online");
        Ember.set(_this, "state", "online");
      };

      window.addEventListener("offline", this._onOfflineHandler);
      window.addEventListener("online", this._onOnlineHandler);
    },
    destroy: function destroy() {
      window.removeEventListener("offline", this._onOfflineHandler);
      window.removeEventListener("online", this._onOnlineHandler);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});