define("splittypie/helpers/inline-svg", ["exports", "splittypie/svgs", "ember-inline-svg/utils/general"], function (_exports, _svgs, _general) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inlineSvg = inlineSvg;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function inlineSvg(path, options) {
    var jsonPath = (0, _general.dottify)(path);
    var svg = Ember.get(_svgs.default, jsonPath); // TODO: Ember.get should return `null`, not `undefined`.
    // if (svg === null && /\.svg$/.test(path))

    if (typeof svg === "undefined" && /\.svg$/.test(path)) {
      svg = Ember.get(_svgs.default, jsonPath.slice(0, -4));
    }

    Ember.assert("No SVG found for " + path, svg);
    svg = (0, _general.applyClass)(svg, options.class);
    return Ember.String.htmlSafe(svg);
  }

  var helper;

  if (Ember.Helper && Ember.Helper.helper) {
    helper = Ember.Helper.helper(function (_ref, options) {
      var _ref2 = _slicedToArray(_ref, 1),
          path = _ref2[0];

      return inlineSvg(path, options);
    });
  } else {
    helper = Ember.Handlebars.makeBoundHelper(function (path, options) {
      return inlineSvg(path, options.hash || {});
    });
  }

  var _default = helper;
  _exports.default = _default;
});