define("splittypie/templates/components/user-balance-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1HoiTDEO",
    "block": "{\"symbols\":[],\"statements\":[[6,\"td\"],[7],[0,\"\\n  \"],[6,\"strong\"],[7],[1,[20,[\"user\",\"name\"]],false],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[9,\"align\",\"right\"],[7],[0,\"\\n  \"],[6,\"span\"],[10,\"class\",[26,[\"label \",[25,\"if\",[[20,[\"isPositive\"]],\"label-success\",\"label-danger\"],null]]]],[7],[0,\"\\n    \"],[1,[25,\"format-money\",[[20,[\"user\",\"balance\"]]],[[\"symbol\",\"format\"],[[20,[\"user\",\"event\",\"currency\",\"code\"]],\"%v %s\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/user-balance-list-item.hbs"
    }
  });

  _exports.default = _default;
});