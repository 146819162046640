define("splittypie/components/event-form", ["exports", "splittypie/components/base-form"], function (_exports, _baseForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseForm.default.extend({
    store: Ember.inject.service(),
    formObject: Ember.computed.alias("event"),
    isSubmitted: Ember.computed.oneWay("event.isSubmitted"),
    didInsertElement: function didInsertElement() {
      if (Ember.get(this, "formObject.isNew")) {
        this.$(".event-name").focus();
      }
    },
    actions: {
      addUser: function addUser() {
        var event = Ember.get(this, "event");
        event.addUser();
      },
      syncOnline: function syncOnline() {
        this.onSyncOnline(Ember.get(this, "event.model"));
      }
    }
  });

  _exports.default = _default;
});