define("splittypie/components/modal-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modal: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.$(".modal").modal().on("hidden.bs.modal", function () {
        Ember.run(function () {
          Ember.get(_this, "modal").trigger("remove");
        });
      });
      Ember.get(this, "modal").one("hide", this, "onHide");
    },
    willDestroyElement: function willDestroyElement() {
      this.onHide();
    },
    onHide: function onHide() {
      var $modal = this.$(".modal");

      if ($modal && $modal.modal) {
        this.$(".modal").modal("hide");
      }
    }
  });

  _exports.default = _default;
});