define("splittypie/routes/event/transactions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notify: Ember.inject.service(),
    transactionRepository: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord("transaction", params.transaction_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var type = Ember.get(model, "typeOrDefault");
      var form = Ember.get(this, "formFactory").createForm(type, model);
      Ember.setProperties(controller, {
        form: form,
        users: Ember.get(this.modelFor("event"), "users")
      });
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: "application"
      });
    },
    actions: {
      delete: function _delete(transaction) {
        var _this = this;

        Ember.get(this, "transactionRepository").remove(transaction).then(function () {
          _this.transitionTo("event.transactions");

          Ember.get(_this, "notify").success("Transaction has been deleted.");
        });
      },
      modelUpdated: function modelUpdated(transaction) {
        var _this2 = this;

        var event = this.modelFor("event");
        Ember.get(this, "transactionRepository").save(event, transaction).then(function () {
          _this2.transitionTo("event.transactions");

          Ember.get(_this2, "notify").success("Transaction has been changed.");
        });
      }
    }
  });

  _exports.default = _default;
});