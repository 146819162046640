define("splittypie/services/online-store", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Store = _emberData.default.Store;

  var _default = Store.extend({
    adapter: Ember.computed("state", function () {
      return "online/application";
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.findAll("currency");
    },
    serializerFor: function serializerFor(modelName) {
      return this._super("online/".concat(modelName));
    },
    adapterFor: function adapterFor(modelName) {
      return this._super("online/".concat(modelName));
    }
  });

  _exports.default = _default;
});