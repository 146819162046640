define("splittypie/templates/event/who-are-you", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "//uejf0R",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[6,\"h3\"],[7],[0,\"Please identify yourself\"],[8],[0,\"\\n\\n\"],[6,\"p\"],[9,\"class\",\"lead\"],[7],[0,\"\\n  Your friend \"],[1,[20,[\"model\",\"users\",\"firstObject\",\"name\"]],false],[0,\" created an event \\\"\"],[1,[20,[\"model\",\"name\"]],false],[0,\"\\\" and wants\\n  to share expenses with you. Please tell us who you are and you will see this event.\\n\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[20,[\"model\",\"users\"]]],null,{\"statements\":[[0,\"  \"],[6,\"button\"],[9,\"class\",\"btn btn-default\"],[3,\"action\",[[19,0,[]],\"chooseUser\",[19,1,[]]]],[7],[0,\"\\n    \"],[1,[19,1,[\"name\"]],false],[0,\"\\n  \"],[8],[0,\"\\n   \\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/event/who-are-you.hbs"
    }
  });

  _exports.default = _default;
});