define("splittypie/repositories/event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    onlineStore: Ember.inject.service(),
    syncer: Ember.inject.service(),
    syncQueue: Ember.inject.service(),
    find: function find(id) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var offlineRecord = Ember.get(_this, "store").findRecord("event", id).then(function (event) {
          resolve(event);
          return event;
        }).catch(function () {
          return false;
        });
        var onlineRecord = Ember.get(_this, "onlineStore").findRecord("event", id).catch(function () {
          return false;
        });
        Ember.RSVP.hash({
          offlineRecord: offlineRecord,
          onlineRecord: onlineRecord
        }).then(function (_ref) {
          var offline = _ref.offlineRecord,
              online = _ref.onlineRecord;

          if (!offline && online) {
            resolve(Ember.get(_this, "syncer").pushEventOffline(online));
          } else if (!online && !offline) {
            reject(new Ember.Error("no record was found"));
          }
        });
      });
    },
    save: function save(event) {
      var _this2 = this;

      var operation = Ember.get(event, "isNew") ? "createEvent" : "updateEvent";
      return event.save().then(function (record) {
        var payload = record.serialize({
          includeId: true
        });
        delete payload.transactions;
        return Ember.get(_this2, "syncQueue").enqueue(operation, payload).then(function () {
          return record;
        });
      });
    },
    remove: function remove(event) {
      var _this3 = this;

      var id = Ember.get(event, "id");
      return event.destroyRecord().then(function (result) {
        return Ember.get(_this3, "syncQueue").enqueue("destroyEvent", {
          id: id
        }).then(function () {
          return result;
        });
      });
    }
  });

  _exports.default = _default;
});