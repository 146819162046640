define("splittypie/templates/event/transactions/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a0dSW9v4",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"transaction-form\",null,[[\"transaction\",\"users\",\"onModelUpdated\",\"title\"],[[20,[\"form\"]],[20,[\"users\"]],[25,\"route-action\",[\"modelUpdated\"],null],\"New Transaction\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/event/transactions/new.hbs"
    }
  });

  _exports.default = _default;
});