define("splittypie/mixins/model-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Mixin.create({
    updateModel: function updateModel(json) {
      this.updateAttributes(json);
      this.updateRelationships(json);
    },
    updateAttributes: function updateAttributes(json) {
      var _this = this;

      this.eachAttribute(function (name) {
        if (json.hasOwnProperty(name)) {
          Ember.set(_this, name, json[name]);
        }
      });
    },
    updateRelationships: function updateRelationships(json) {
      var _this2 = this;

      this.eachRelationship(function (name, descriptor) {
        if (json.hasOwnProperty(name)) {
          var modelName = descriptor.type;

          if (descriptor.kind === "belongsTo") {
            var id = json[name];

            var model = _this2.store.peekRecord(modelName, id);

            Ember.set(_this2, name, model);
          } else if (descriptor.kind === "hasMany" && Ember.isArray(json[name])) {
            var array = json[name];
            var result = [];

            if (array.length === 0) {
              result = null;
            } else if (_typeof(array[0]) === "object") {
              result = _this2._synchronizeWithNewArray(modelName, name, array);
            } else {
              result = _this2.store.peekAll(modelName).filter(function (item) {
                return array.indexOf(item.get("id")) > -1;
              });
            }

            Ember.set(_this2, name, result);
          }
        }
      });
    },
    _synchronizeWithNewArray: function _synchronizeWithNewArray(modelName, name, newArray) {
      var _this3 = this;

      var currentArray = Ember.get(this, name);
      return newArray.map(function (newRecord) {
        var record = currentArray.findBy("id", newRecord.id);

        if (record) {
          record.updateAttributes(newRecord);
        } else {
          record = _this3.store.createRecord(modelName, newRecord);
        }

        return record;
      });
    }
  });

  _exports.default = _default;
});