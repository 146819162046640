define("splittypie/templates/components/transaction-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zMP5Udx2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"transaction\",\"date\"]]],null,{\"statements\":[[0,\"  \"],[1,[25,\"calendar-page\",null,[[\"date\"],[[20,[\"transaction\",\"date\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[6,\"div\"],[9,\"class\",\"transaction-list-item-description\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"transaction\",\"isTransfer\"]]],null,{\"statements\":[[0,\"    \"],[6,\"strong\"],[7],[1,[20,[\"transaction\",\"payer\",\"name\"]],false],[8],[0,\" settled up with \"],[6,\"strong\"],[7],[1,[20,[\"transaction\",\"participants\",\"firstObject\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"strong\"],[7],[1,[20,[\"transaction\",\"payer\",\"name\"]],false],[8],[0,\" paid for \"],[6,\"em\"],[7],[1,[20,[\"transaction\",\"name\"]],false],[8],[6,\"br\"],[7],[8],[0,\"\\n    \"],[1,[25,\"inline-svg\",[\"user\"],[[\"class\"],[\"icon\"]]],false],[0,\" \"],[6,\"small\"],[7],[1,[18,\"participants\"],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"transaction-list-item-amount\"],[7],[0,\"\\n  \"],[6,\"strong\"],[7],[0,\"\\n    \"],[1,[25,\"format-money\",[[20,[\"transaction\",\"amount\"]]],[[\"symbol\",\"format\"],[[20,[\"transaction\",\"event\",\"currency\",\"code\"]],\"%v %s\"]]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/transaction-list-item.hbs"
    }
  });

  _exports.default = _default;
});