define("splittypie/templates/components/onboarding-transaction-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vhTRaz5H",
    "block": "{\"symbols\":[],\"statements\":[[6,\"p\"],[7],[0,\"\\n  Add your first transaction\\n\"],[8],[0,\"\\n\"],[6,\"img\"],[9,\"src\",\"/assets/arrow-right.png\"],[9,\"alt\",\"right arrow\"],[7],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/onboarding-transaction-add.hbs"
    }
  });

  _exports.default = _default;
});