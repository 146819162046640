define("splittypie/templates/components/settlement-transfer-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XXNISwgb",
    "block": "{\"symbols\":[\"transfer\"],\"statements\":[[6,\"h4\"],[7],[0,\"How to settle all debts?\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"anyTransfers\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"panel panel-default\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"panel-body\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"transfers\"]]],null,{\"statements\":[[0,\"        \"],[6,\"li\"],[9,\"class\",\"settlement-item\"],[7],[0,\"\\n          \"],[6,\"strong\"],[7],[1,[19,1,[\"sender\",\"name\"]],false],[8],[0,\" owes \"],[6,\"strong\"],[7],[1,[19,1,[\"recipient\",\"name\"]],false],[8],[0,\"\\n          \"],[6,\"span\"],[9,\"class\",\"text-nowrap\"],[7],[0,\"\\n            \"],[1,[25,\"format-money\",[[19,1,[\"amount\"]]],[[\"symbol\",\"format\"],[[19,1,[\"currency\",\"code\"]],\"%v %s\"]]],false],[0,\"\\n          \"],[8],[0,\"\\n          \"],[6,\"button\"],[9,\"class\",\"btn btn-sm btn-success\"],[3,\"action\",[[19,0,[]],[25,\"action\",[[19,0,[]],[20,[\"settleUp\"]],[19,1,[]]],null]]],[7],[0,\"\\n            Settle Up!\\n          \"],[8],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"alert alert-info\"],[9,\"role\",\"alert\"],[7],[0,\"\\n    There is no need to settle anything.\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/settlement-transfer-list.hbs"
    }
  });

  _exports.default = _default;
});