define("splittypie/services/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    onConfirm: function onConfirm(action) {
      var _this = this;

      this.trigger("show", {
        name: "confirm",
        actions: {
          ok: function ok() {
            if (typeof action === "function") {
              action();
            }

            _this.trigger("hide");
          }
        }
      });
    }
  });

  _exports.default = _default;
});