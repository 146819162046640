define("splittypie/serializers/offline/event", ["exports", "ember-data", "ember-localforage-adapter/serializers/localforage"], function (_exports, _emberData, _localforage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _localforage.default.extend(EmbeddedRecordsMixin, {
    attrs: {
      users: {
        embedded: "always"
      },
      transactions: {
        embedded: "always"
      }
    }
  });

  _exports.default = _default;
});