define("splittypie/initializers/user-context", ["exports", "splittypie/services/user-context"], function (_exports, _userContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register("service:user-context", _userContext.default); // inject user-context to controllers

    application.inject("controller", "userContext", "service:user-context");
  }

  var _default = {
    name: "user-context",
    initialize: initialize
  };
  _exports.default = _default;
});