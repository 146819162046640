define("splittypie/templates/components/scroll-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o530xHAU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"a\"],[10,\"href\",[18,\"href\"],null],[10,\"title\",[18,\"label\"],null],[7],[0,\"\\n\"],[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"    \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[18,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/scroll-to.hbs"
    }
  });

  _exports.default = _default;
});