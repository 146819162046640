define("splittypie/templates/components/switch-event-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8fs8KGPr",
    "block": "{\"symbols\":[\"event\"],\"statements\":[[6,\"button\"],[9,\"class\",\"btn btn-link btn-lg dropdown-toggle btn-change-event\"],[9,\"type\",\"button\"],[9,\"id\",\"dropDownEvents\"],[9,\"data-toggle\",\"dropdown\"],[9,\"aria-haspopup\",\"true\"],[9,\"aria-expanded\",\"true\"],[7],[0,\"\\n  \"],[1,[20,[\"selected\",\"name\"]],false],[0,\"\\n  \"],[6,\"span\"],[9,\"class\",\"caret\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"ul\"],[9,\"class\",\"dropdown-menu\"],[9,\"aria-labelledby\",\"dropDownEvents\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"anyOtherEvents\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[9,\"class\",\"dropdown-header\"],[7],[0,\"Switch to\"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"otherEvents\"]]],null,{\"statements\":[[0,\"      \"],[6,\"li\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"event.index\",[19,1,[\"id\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"inline-svg\",[\"calendar\"],[[\"class\"],[\"icon\"]]],false],[0,\" \"],[1,[19,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[6,\"li\"],[9,\"role\",\"separator\"],[9,\"class\",\"divider\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[6,\"li\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"new\"],null,{\"statements\":[[0,\"      \"],[1,[25,\"inline-svg\",[\"plus\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n      Add New Event\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/switch-event-dropdown.hbs"
    }
  });

  _exports.default = _default;
});