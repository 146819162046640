define("splittypie/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute("event.index"), this.toRoute("event.transactions"), this.use("toLeft"), this.reverse("toRight"));
  }
});