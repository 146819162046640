define("splittypie/initializers/extensions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function initialize(application) {
    window.App = application;

    Array.prototype.flatten = function () {
      var result = [];
      this.forEach(function (element) {
        var array = Ember.isArray(element) ? element.flatten() : [element];
        result.push.apply(result, _toConsumableArray(array));
      });
      return result;
    };
  }

  var _default = {
    name: "extensions",
    initialize: initialize
  };
  _exports.default = _default;
});