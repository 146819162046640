define("splittypie/components/back-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "button",
    classNames: ["btn", "btn-link"],
    click: function click() {
      window.history.back();
      return false;
    }
  });

  _exports.default = _default;
});