define("splittypie/components/user-balance-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    anyUsers: Ember.computed.notEmpty("users"),
    usersSorting: ["balance:desc"],
    // eslint-disable-line
    sortedUsers: Ember.computed.sort("users", "usersSorting")
  });

  _exports.default = _default;
});