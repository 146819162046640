define("splittypie/components/my-checkbox-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // possible passed-in values with their defaults:
    content: null,
    optionValuePath: "id",
    optionLabelPath: "title",
    action: function action() {},
    // action to fire on change
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, "content")) {
        Ember.set(this, "content", []);
      }
    },
    actions: {
      change: function change() {
        var inputs = this.$("input");
        var content = Ember.get(this, "content");
        var selection = [];
        inputs.each(function (index, input) {
          if (input.checked) {
            selection.pushObject(content.objectAt(index));
          }
        });
        Ember.set(this, "selection", selection);
      }
    }
  });

  _exports.default = _default;
});