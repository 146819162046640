define("splittypie/components/add-transaction-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["add-transaction-button"],
    anyTransactions: Ember.computed.notEmpty("event.transactions")
  });

  _exports.default = _default;
});