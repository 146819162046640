define("splittypie/templates/modals/settle-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "utZJCOD8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"modal-header\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"close\"],[9,\"data-dismiss\",\"modal\"],[9,\"aria-hidden\",\"true\"],[9,\"aria-label\",\"Dismiss\"],[7],[0,\"×\"],[8],[0,\"\\n    \"],[6,\"h4\"],[9,\"class\",\"modal-title\"],[7],[0,\"Settle Up!\"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal-body\"],[7],[0,\"\\n    All settled between \"],[1,[20,[\"model\",\"transfer\",\"sender\",\"name\"]],false],[0,\" and \"],[1,[20,[\"model\",\"transfer\",\"recipient\",\"name\"]],false],[0,\"?\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"modal-footer\"],[7],[0,\"\\n    \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"btn btn-primary\"],[3,\"action\",[[19,0,[]],\"invokeAction\",[20,[\"model\",\"actions\",\"yes\"]]]],[7],[0,\"Yes\"],[8],[0,\"\\n    \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"btn btn-default\"],[9,\"data-dismiss\",\"modal\"],[7],[0,\"No\"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/modals/settle-up.hbs"
    }
  });

  _exports.default = _default;
});