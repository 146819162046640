define("splittypie/templates/components/user-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VhNGEwTn",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"form-group \",[25,\"if\",[[20,[\"user\",\"formErrors\",\"name\",\"messages\"]],\"has-error\"],null]]]],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"input-group\"],[7],[0,\"\\n    \"],[1,[25,\"input\",null,[[\"value\",\"class\",\"placeholder\"],[[20,[\"user\",\"name\"]],\"user-name form-control\",[20,[\"placeholder\"]]]]],false],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"input-group-btn\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"class\",\"btn btn-danger remove-user\"],[9,\"type\",\"button\"],[10,\"disabled\",[18,\"isRemoveDisabled\"],null],[3,\"action\",[[19,0,[]],\"delete\",[20,[\"user\"]]]],[7],[0,\"Delete\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"help-block with-errors\"],[7],[0,\"\\n    \"],[1,[20,[\"user\",\"formErrors\",\"name\",\"message\"]],false],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/user-form.hbs"
    }
  });

  _exports.default = _default;
});