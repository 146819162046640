define("splittypie/forms/event", ["exports", "ember-cp-validations", "splittypie/forms/form-object"], function (_exports, _emberCpValidations, _formObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
        max: 50
      })]
    },
    currency: (0, _emberCpValidations.validator)("presence", true),
    users: (0, _emberCpValidations.validator)("has-many")
  });

  var _default = _formObject.default.extend(Validations, {
    modelName: "event",
    innerForms: ["users"],
    // eslint-disable-line
    isOffline: Ember.computed.oneWay("model.isOffline"),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var model = Ember.get(this, "model");
      Ember.setProperties(this, Ember.getProperties(model, "name", "currency"));
      var users = Ember.getWithDefault(model, "users", []).map(function (user) {
        return _this.createInnerForm("user", user);
      });
      Ember.set(this, "users", users);
    },
    addUser: function addUser() {
      var emptyUserForm = this.createInnerForm("user", Ember.Object.create());
      Ember.get(this, "users").pushObject(emptyUserForm);
    },
    updateModelAttributes: function updateModelAttributes() {
      var model = Ember.get(this, "model");
      Ember.setProperties(model, Ember.getProperties(this, "name", "currency"));
      Ember.set(model, "users", Ember.get(this, "users").getEach("model"));
    }
  });

  _exports.default = _default;
});