define("splittypie/utils/left-pad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = leftPad;

  function leftPad(pad, string) {
    return pad.substring(0, pad.length - string.length) + string;
  }
});