define("splittypie/routes/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      var errorName = this.getErrorName(model);
      Ember.set(controller, "partialName", "shared/errors/".concat(errorName));
    },
    getErrorName: function getErrorName(error) {
      if (error && error.message) {
        if (error.message.indexOf("no record was found") > -1) {
          return "not-found";
        }
      }

      return "unknown";
    }
  });

  _exports.default = _default;
});