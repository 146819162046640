define("splittypie/components/my-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // possible passed-in values with their defaults:
    options: null,
    prompt: null,
    optionValuePath: "id",
    optionLabelPath: "title",
    action: function action() {},
    // action to fire on change
    init: function init() {
      this._super.apply(this, arguments);

      if (!Ember.get(this, "options")) {
        Ember.set(this, "options", []);
      }
    },
    // eslint-disable-next-line
    valueDidChanged: Ember.on("init", Ember.observer("value", function () {
      var options = Ember.get(this, "options");
      var value = Ember.get(this, "value");

      if (value) {
        var key = Ember.get(this, "optionValuePath");
        var selected = options.findBy(key, value);
        Ember.set(this, "selected", selected);
      }
    })),
    actions: {
      change: function change() {
        var selectEl = this.$("select")[0];
        var selectedIndex = selectEl.selectedIndex;
        var options = Ember.get(this, "options"); // decrement index by 1 if we have a prompt

        var hasPrompt = !!Ember.get(this, "prompt");
        var contentIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;
        var selected = options.objectAt(contentIndex);
        Ember.set(this, "selected", selected);

        if (selected) {
          Ember.set(this, "value", Ember.get(selected, Ember.get(this, "optionValuePath")));
        } else {
          Ember.set(this, "value", null);
        }
      }
    }
  });

  _exports.default = _default;
});