define("splittypie/components/calendar-page", ["exports", "splittypie/utils/left-pad"], function (_exports, _leftPad) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["calendar-page"],
    day: Ember.computed("date", function () {
      var date = Ember.get(this, "date");
      return (0, _leftPad.default)("00", new Date(date).getUTCDate().toString());
    }),
    month: Ember.computed("date", function () {
      var date = Ember.get(this, "date");
      var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return months[new Date(date).getUTCMonth()];
    })
  });

  _exports.default = _default;
});