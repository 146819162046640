define("splittypie/templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X8KfQ645",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"event-form\",null,[[\"currencies\",\"event\",\"onModelUpdated\",\"title\"],[[20,[\"currencies\"]],[20,[\"event\"]],[25,\"route-action\",[\"modelUpdated\"],null],\"New Event\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/new.hbs"
    }
  });

  _exports.default = _default;
});