define("splittypie/routes/event/transactions/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notify: Ember.inject.service(),
    transactionRepository: Ember.inject.service(),
    userContext: Ember.inject.service(),
    model: function model(params) {
      var amount = Ember.getWithDefault(params, "amount", null);
      var date = Ember.getWithDefault(params, "date", (0, _moment.default)().format("YYYY-MM-DD"));
      var event = this.modelFor("event");
      var name = Ember.getWithDefault(params, "name", null);
      var participants = Ember.get(event, "users");
      var payer = Ember.get(this, "userContext.currentUser");
      return Ember.Object.create({
        amount: amount,
        date: date,
        name: name,
        participants: participants,
        payer: payer
      });
    },
    afterModel: function afterModel(model) {
      Ember.set(model, "event", this.modelFor("event"));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var users = Ember.get(this.modelFor("event"), "users");
      var form = Ember.get(this, "formFactory").createForm("expense", model);
      Ember.setProperties(controller, {
        form: form,
        users: users
      });
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: "application"
      });
    },
    actions: {
      modelUpdated: function modelUpdated(transaction) {
        var _this = this;

        var event = this.modelFor("event");
        Ember.get(this, "transactionRepository").save(event, transaction).then(function () {
          _this.transitionTo("event.transactions");

          Ember.get(_this, "notify").success("New transaction has been added");
        });
      }
    }
  });

  _exports.default = _default;
});