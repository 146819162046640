define("splittypie/routes/event/who-are-you", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userContext: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render({
        into: "application"
      });
    },
    actions: {
      chooseUser: function chooseUser(user) {
        var event = this.modelFor("event");
        Ember.get(this, "userContext").change(event, user);
        this.transitionTo("event.index", event);
      }
    }
  });

  _exports.default = _default;
});