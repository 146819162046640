define("splittypie/templates/components/my-checkbox-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yPgrObVR",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[20,[\"content\"]]],[[\"key\"],[\"@identity\"]],{\"statements\":[[0,\"    \"],[6,\"label\"],[7],[0,\"\\n        \"],[6,\"input\"],[9,\"type\",\"checkbox\"],[10,\"checked\",[25,\"is-included\",[[19,1,[]],[20,[\"selection\"]]],null],null],[3,\"action\",[[19,0,[]],\"change\"],[[\"on\"],[\"change\"]]],[7],[8],[0,\"\\n        \"],[1,[25,\"read-path\",[[19,1,[]],[20,[\"optionLabelPath\"]]],null],false],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/my-checkbox-list.hbs"
    }
  });

  _exports.default = _default;
});