define("splittypie/services/user-country-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var error = Ember.Logger.error;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    countryCode: null,
    getCountryCode: function getCountryCode() {
      var countryCode = Ember.get(this, "countryCode");

      if (countryCode === null) {
        countryCode = Ember.get(this, "ajax").request("http://ip-api.com/json").then(function (data) {
          return data && data.countryCode;
        }).catch(function (e) {
          error(e);
        });
        Ember.set(this, "countryCode", countryCode);
      }

      return countryCode;
    }
  });

  _exports.default = _default;
});