define("splittypie/templates/components/transaction-list-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iBP75vf2",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[7],[0,\"\\n  Showing \"],[1,[25,\"pluralize\",[[20,[\"count\"]],\"expense\"],null],false],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[6,\"div\"],[7],[0,\"\\n  Total of \"],[6,\"strong\"],[7],[1,[25,\"format-money\",[[20,[\"total\"]]],[[\"symbol\",\"format\"],[[20,[\"currency\"]],\"%v %s\"]]],false],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/transaction-list-header.hbs"
    }
  });

  _exports.default = _default;
});