define("splittypie/templates/event/transactions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7KuaWYaT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"is-equal\",[[20,[\"form\",\"formType\"]],\"transfer\"],null]],null,{\"statements\":[[0,\"  \"],[1,[25,\"transfer-form\",null,[[\"transfer\",\"onDelete\",\"title\"],[[20,[\"form\"]],[25,\"route-action\",[\"delete\"],null],\"View Transfer\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,\"transaction-form\",null,[[\"transaction\",\"users\",\"onDelete\",\"onModelUpdated\",\"title\"],[[20,[\"form\"]],[20,[\"users\"]],[25,\"route-action\",[\"delete\"],null],[25,\"route-action\",[\"modelUpdated\"],null],\"Edit Transaction\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/event/transactions/edit.hbs"
    }
  });

  _exports.default = _default;
});