define("splittypie/utils/parse-transaction", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseTransaction;

  var parseAmount = function parseAmount(text) {
    var amount = parseFloat(text.replace(",", "."));
    if (isNaN(amount)) return null;
    return amount;
  };

  var parseMomentDate = function parseMomentDate(text) {
    if (text.length > 5) {
      return (0, _moment.default)(text, "YYYYMMDD");
    } else if (text.length > 2) {
      return (0, _moment.default)(text, "MMDD");
    }

    return (0, _moment.default)(text, "DD");
  };

  var parseDate = function parseDate(date) {
    return date.format("YYYY-MM-DD");
  };

  function parseTransaction(transactionText) {
    if (!transactionText || !transactionText.trim()) return null;
    var parts = transactionText.trim().split(" ");
    var onlyMePattern = /\.\s*me\s*$/;
    var date = (0, _moment.default)().utc();
    var name = null;
    var amount = null;
    var onlyMe = false;

    if (parts.length <= 2) {
      amount = parseAmount(parts[0]);
      name = amount ? parts[1] : parts.join(" ");
    } else if (parseAmount(parts[1])) {
      amount = parseAmount(parts[1]);
      date = parseMomentDate(parts[0]);
      name = parts.slice(2).join(" ");
    } else {
      amount = parseAmount(parts[0]);
      name = parts.slice(1).join(" ");
    }

    if (!date.isValid()) date = (0, _moment.default)();

    if (name && onlyMePattern.test(name)) {
      name = name.replace(onlyMePattern, "");
      onlyMe = true;
    }

    return {
      amount: amount,
      date: parseDate(date),
      name: name || null,
      onlyMe: onlyMe
    };
  }
});