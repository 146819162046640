define("splittypie/templates/event/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VqoIBZhm",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"user-balance-list\",null,[[\"users\"],[[20,[\"model\",\"users\"]]]]],false],[0,\"\\n\"],[1,[25,\"settlement-transfer-list\",null,[[\"users\",\"settleUp\"],[[20,[\"model\",\"users\"]],[25,\"route-action\",[\"settleUp\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/event/index.hbs"
    }
  });

  _exports.default = _default;
});