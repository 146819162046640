define("splittypie/templates/event/transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4k6WoAG6",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"transaction-list\",null,[[\"transactions\",\"users\",\"currentUser\",\"onEdit\"],[[20,[\"model\",\"transactions\"]],[20,[\"model\",\"users\"]],[20,[\"userContext\",\"currentUser\"]],[25,\"route-action\",[\"edit\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/event/transactions.hbs"
    }
  });

  _exports.default = _default;
});