define("splittypie/forms/expense", ["exports", "ember-cp-validations", "splittypie/forms/form-object"], function (_exports, _emberCpValidations, _formObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("length", {
        max: 50
      })]
    },
    amount: {
      validators: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("number", {
        allowString: true
      })]
    },
    payer: (0, _emberCpValidations.validator)("presence", true),
    participants: (0, _emberCpValidations.validator)("presence", true)
  });

  var _default = _formObject.default.extend(Validations, {
    modelName: "transaction",
    event: Ember.computed.oneWay("model.event"),
    isSaving: Ember.computed.oneWay("event.isSaving"),
    init: function init() {
      this._super.apply(this, arguments);

      var model = Ember.get(this, "model");
      Ember.setProperties(this, Ember.getProperties(model, "name", "isTransfer", "date", "amount", "payer", "participants"));
      Ember.set(this, "participants", Ember.getWithDefault(model, "participants", []).toArray());
    },
    updateModelAttributes: function updateModelAttributes() {
      var model = Ember.get(this, "model");
      Ember.setProperties(model, Ember.getProperties(this, "name", "date", "amount", "payer", "participants"));
    }
  });

  _exports.default = _default;
});