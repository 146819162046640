define("splittypie/templates/components/switch-user-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qPP5oCqc",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[6,\"button\"],[9,\"class\",\"btn btn-link btn-lg dropdown-toggle btn-change-user\"],[9,\"type\",\"button\"],[9,\"id\",\"dropDownUsers\"],[9,\"data-toggle\",\"dropdown\"],[9,\"aria-haspopup\",\"true\"],[9,\"aria-expanded\",\"true\"],[9,\"aria-label\",\"Switch User\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"iconOnly\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"inline-svg\",[\"user\"],[[\"class\"],[\"icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Viewing as \"],[1,[20,[\"selected\",\"name\"]],false],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"caret\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]}],[8],[0,\"\\n\"],[6,\"ul\"],[9,\"class\",\"dropdown-menu dropdown-menu-right\"],[9,\"aria-labelledby\",\"dropDownUsers\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"iconOnly\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[9,\"class\",\"dropdown-header\"],[7],[0,\"Viewing as \"],[1,[20,[\"selected\",\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[6,\"li\"],[9,\"class\",\"dropdown-header\"],[7],[0,\"Switch user to\"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"otherUsers\"]]],null,{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[0,\"\\n      \"],[6,\"a\"],[9,\"href\",\"#\"],[3,\"action\",[[19,0,[]],[25,\"action\",[[19,0,[]],[20,[\"onChange\"]],[19,1,[]]],null]]],[7],[0,\"\\n        \"],[1,[19,1,[\"name\"]],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "splittypie/templates/components/switch-user-dropdown.hbs"
    }
  });

  _exports.default = _default;
});