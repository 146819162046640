define("splittypie/adapters/offline/application", ["exports", "ember-localforage-adapter/adapters/localforage", "splittypie/utils/generate-unique-id"], function (_exports, _localforage, _generateUniqueId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _localforage.default.extend({
    generateIdForRecord: _generateUniqueId.default
  });

  _exports.default = _default;
});