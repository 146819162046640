define("splittypie/mixins/remember-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    scrollSelector: window,
    activate: function activate() {
      var _this = this;

      this._super.apply(this, arguments);

      var lastScroll = Ember.get(this, "lastScroll");

      if (lastScroll) {
        Ember.run.next(function () {
          Ember.$(_this.scrollSelector).scrollTop(lastScroll);
        });
      } else {
        Ember.$(this.scrollSelector).scrollTop(0);
      }
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      Ember.set(this, "lastScroll", Ember.$(this.scrollSelector).scrollTop());
    }
  });

  _exports.default = _default;
});