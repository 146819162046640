define("splittypie/models/event", ["exports", "splittypie/mixins/model-mixin", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _modelMixin, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_modelMixin.default, {
    name: (0, _attr.default)("string"),
    isOffline: (0, _attr.default)("boolean"),
    currency: (0, _relationships.belongsTo)("currency", {
      async: true
    }),
    users: (0, _relationships.hasMany)("user", {
      async: false
    }),
    transactions: (0, _relationships.hasMany)("transaction", {
      async: false
    }),
    url: Ember.computed("id", function () {
      return "https://splittypie.com/".concat(Ember.get(this, "id"));
    })
  });

  _exports.default = _default;
});