define("splittypie/adapters/offline/transaction", ["exports", "ember-localforage-adapter/adapters/localforage", "splittypie/utils/generate-unique-id"], function (_exports, _localforage, _generateUniqueId) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _localforage.default.extend({
    generateIdForRecord: _generateUniqueId.default,
    updateRecord: function updateRecord() {
      return Ember.RSVP.resolve();
    },
    createRecord: function createRecord() {
      return Ember.RSVP.resolve();
    }
  });

  _exports.default = _default;
});