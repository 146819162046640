define("splittypie/initializers/offline-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember$Logger = Ember.Logger,
      error = _Ember$Logger.error,
      debug = _Ember$Logger.debug;
  var _default = {
    name: "offline-support",
    initialize: function initialize(application) {
      var notify = application.__container__.lookup("service:notify");

      if ("serviceWorker" in window.navigator) {
        window.navigator.serviceWorker.register("/offline-support.js").then(function (registration) {
          var isUpdate = !!registration.active;
          debug("Offline Support Registered", registration);

          registration.onupdatefound = function () {
            debug("A new Service Worker version has been found...");

            registration.installing.onstatechange = function () {
              if (this.state === "installed") {
                debug("Service Worker Installed.");

                if (isUpdate) {
                  notify.info("Application has been updated. Please reload page for the new version.", {
                    closeAfter: null
                  });
                } else {
                  notify.success("App ready for offline use.");
                }
              } else {
                debug("New Service Worker state: ", this.state);
              }
            };
          };
        }).catch(function (err) {
          error(err);
        });
      }
    }
  };
  _exports.default = _default;
});