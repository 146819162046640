define("splittypie/components/user-balance-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "tr",
    classNames: ["user-balance-list-item"],
    isPositive: Ember.computed.gte("user.balance", 0)
  });

  _exports.default = _default;
});