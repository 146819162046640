define("splittypie/validators/array", ["exports", "ember-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    init: function init() {
      // this call is necessary, don't forget it!
      this._super();

      this.dependentValidationKeys.pushObject("".concat(this.property, ".@each.isValid"));
    },
    call: function call() {
      var arrayToValidate = Ember.get(this.model, this.property);

      if (!arrayToValidate) {
        return;
      }

      if (!Ember.isArray(arrayToValidate)) {
        throw new Error("property ".concat(this.property, " is not an array, but array validator is used"));
      }

      if (arrayToValidate.isAny("isValid", false)) {
        this.errors.pushObject("not every element valid");
      }
    }
  });

  _exports.default = _default;
});