define("splittypie/forms/transfer", ["exports", "splittypie/forms/form-object"], function (_exports, _formObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _formObject.default.extend({
    modelName: "transaction",
    event: Ember.computed.oneWay("model.event"),
    init: function init() {
      this._super.apply(this, arguments);

      var model = Ember.get(this, "model");
      Ember.setProperties(this, Ember.getProperties(model, "name", "date", "amount"));
      Ember.set(this, "sender", Ember.get(model, "payer"));
      Ember.set(this, "recipient", Ember.get(model, "participants.firstObject"));
    },
    updateModelAttributes: function updateModelAttributes() {
      throw new Error("not-implemented");
    }
  });

  _exports.default = _default;
});