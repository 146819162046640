define("splittypie/components/switch-event-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "div",
    classNames: ["dropdown", "event-dropdown"],
    otherEvents: Ember.computed("events", "selected", function () {
      var events = Ember.get(this, "events");
      var selected = Ember.get(this, "selected");
      return events.rejectBy("id", selected.get("id"));
    }),
    anyOtherEvents: Ember.computed.notEmpty("otherEvents")
  });

  _exports.default = _default;
});